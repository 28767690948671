import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import { PageHero, Seo } from "../components";

const DailyBread = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const getXml = async () => {
      try {
        const res = await fetch("https://rubf-util.herokuapp.com/dailybread", {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "text/xml; charset=utf-8",
          },
          redirect: "error",
          referrerPolicy: "no-referrer",
        });
        const text = await res.json();
        const body = text.content;
        getContent(body);
      } catch (err) {
        setContent({ status: "error", message: err });
      }
    };

    if (typeof window !== "undefined") {
      getXml();
    } else {
      setContent(null);
    }
  }, []);

  const getContent = (text) => {
    const parser = new DOMParser();
    const data = parser.parseFromString(text, "text/xml");
    const title = data.getElementsByTagName("title")[0].childNodes[0].nodeValue;
    const url = data.getElementsByTagName("link")[0].childNodes[0].nodeValue;
    const passage =
      data.getElementsByTagName("passage")[0].childNodes[0].nodeValue;
    const keyVerse =
      data.getElementsByTagName("keyverse")[0].childNodes[0].nodeValue;
    const body = data.getElementsByTagName("body")[0].childNodes[0].nodeValue;
    const prayer =
      data.getElementsByTagName("prayer")[0].childNodes[0].nodeValue;
    const oneWord =
      data.getElementsByTagName("oneword")[0].childNodes[0].nodeValue;

    setContent({ title, url, passage, keyVerse, body, prayer, oneWord });
  };

  const DailyBreadContent = () => {
    const { title, passage, keyVerse, body, prayer, oneWord } = content;
    const paragraphs = body.split("<br>");

    return (
      <article className="dailybread-content">
        <h3>{title}</h3>
        <hr />
        <p className="center">Passage: {passage} </p>
        <p>Key Verse: {keyVerse}</p>
        <div>
          {paragraphs.map((p, index) => (
            <p key={index}>{p}</p>
          ))}
        </div>
        <p>
          <strong>Prayer:</strong>
          {prayer.slice(7)}
        </p>
        <p>
          <strong>One Word:</strong>
          {oneWord.slice(9)}
        </p>
        <a
          href="https://ubf.org/dailybread/today"
          target="_blank"
          rel="noopener noreferrer"
        >
          More Daily Bread
        </a>
      </article>
    );
  };

  const SkeletonLoader = () => (
    <div className="skeleton">
      <p className="skeleton-title">&nbsp;</p>
      <hr />
      <p className="skeleton-short">&nbsp;</p>
      <p className="skeleton-short">&nbsp;</p>
      <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );

  const ErrorBox = () => (
    <div className="error-box">
      <h3>Oops! Something went wrong ;(</h3>
      <hr />
      <p>Our best technician is on this issue right now. Be back soon.</p>
    </div>
  );

  return (
    <>
      <Seo
        title="Daily Bread"
        description="Daily Bread is spiritual nourishment for us each day. The source is from the headquarters of our ministry."
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/mae-mu-Emhz3miT6mo-unsplash.jpg"}
            alt="Bread in a basket on a table with dimmed background"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="Daily Bread"
        text="Daily Bread is spiritual nourishment for us each day. The source is from the headquarters of our ministry."
      />
      <div className="page-main">
        <DailyBreadWrapper>
          {content ? (
            content.status === "error" ? (
              <ErrorBox />
            ) : (
              <DailyBreadContent />
            )
          ) : (
            <SkeletonLoader />
          )}
        </DailyBreadWrapper>
      </div>
    </>
  );
};

export default DailyBread;

const DailyBreadWrapper = styled.div`
  ${tw`bg-secondary mx-4 md:(mx-8 p-8) rounded-2xl p-4 2xl:mx-auto my-12`}
  box-shadow:12px 12px 24px 12px var(--gray-one);

  /* daily bread div */
  .dailybread-content > h3 {
    ${tw`text-center opacity-100 leading-10`}
  }

  .dailybread-content div {
    ${tw`my-8`}
  }

  .dailybread-content div > p {
    ${tw`my-4`}
    text-indent:2rem;
  }

  p:last-of-type {
    ${tw`mt-4`}
  }

  .dailybread-content > a {
    ${tw`block w-[200px] h-[40px] bg-active text-white rounded-lg mx-auto mt-12 shadow-one active:shadow-sm text-center leading-[40px] `}
  }

  /* skeleton loader */
  .skeleton {
    ${tw`opacity-70`}
  }

  .skeleton p {
    ${tw`mb-2 bg-gray-300 text-[0.5rem] leading-[0.5rem] rounded-sm`}
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .skeleton p.skeleton-title {
    ${tw`w-2/5 mx-auto text-base`}
  }

  .skeleton p.skeleton-short {
    ${tw`w-24`}
  }

  .skeleton div {
    ${tw`my-6`}
  }

  .skeleton div > p:last-of-type {
    ${tw`w-3/5`}
  }

  @keyframes skeleton-loading {
    0% {
      ${tw`bg-[#a3b8c2]`}
    }
    100% {
      ${tw`bg-[#f0f3f5]`}
    }
  }

  /* error div */
  div.error-box > h3 {
    ${tw`text-center`}
  }

  div.error-box > p {
    ${tw`whitespace-pre-line py-1 text-center w-full`}
  }
`;
